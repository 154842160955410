
import PropTypes from 'prop-types';
import { sanitize } from 'dompurify';

const FormRadio = (props) => {

    const { stepDetails, errors, register } = props;
    const isChecked = (source) => {
        if (source?.Value?.toLowerCase() === stepDetails?.fieldValue?.toLowerCase() || source?.Selected?.toLowerCase() === 'yes')
            return true;
    };
    return (
        <>
            {
                stepDetails.promptLabel ?
                    <label className={stepDetails.promptLabelClass + ' ' + (stepDetails.isFlex && stepDetails.promptLabelColumns && stepDetails.promptLabelColumns !== '' ? ' col-' + stepDetails.promptLabelColumns : '')} >{stepDetails.promptLabel}<span className='marketplaceProcessRequired'>{stepDetails.validateRequired && ' *'}</span></label>
                    : null
            }
            {stepDetails.selectValues.map((source, index) => (
                <div key={index} className='flex-radio'>
                    <div key={index} className={'form-check marketplaceProcessRadioButtonFieldSet ' + (stepDetails.isFlex && stepDetails.promptValueColumns ? ' col-' + stepDetails.promptValueColumns : '')}>
                        <input type='radio' name={stepDetails.reference} key={index}  {...register(stepDetails.referenceValidate, { required: stepDetails.validateRequired })} checked={isChecked(source)} value={source.Value} />
                        <label dangerouslySetInnerHTML={{ __html: sanitize(source.Label) }}></label>
                    </div>
                </div>
            ))}
            <div className={stepDetails.isFlex ? 'flex-label' : ''} style={{ marginTop: '25px' }}>
                {errors && errors[stepDetails.referenceValidate]?.type === 'required' && (
                    <label className={'marketplaceProcessRequired ' + (stepDetails.isFlex ? 'flex-label' : '')}>This field is required.</label>
                )}
            </div>
        </>
    );
};

export default FormRadio;

FormRadio.propTypes = {
    stepDetails: PropTypes.any.isRequired,
    errors: PropTypes.any,
    register: PropTypes.any
};
