import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { Container } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { isMobile } from 'react-device-detect';

import MarketplaceMenu from './components/shared/marketplacemenu/marketplacemenu';
import { GetCookie, QueryStringToJSON } from './utils/core/coreutil';
import { MarketplaceSessionGUID, PromptSources, UserSessionGUID, UserSessionID } from './redux/core/corereducer';
import CoreAPI from './api/core/coreapi';
import MarketplaceRoutes from './routes/marketplaceroutes';
import MarketplaceSpinner from './components/shared/marketplacespinner/marketplacespinner';
import './style.css';
import { hotjar } from 'react-hotjar';

export default function App() {

    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [refresh, setRefresh] = useState(false);
    const [error, setError] = useState(false);
    const [numOfAttempts, setNumOfAttempts] = useState(0);
    const [clearCookie, setClearCookie] = useState(false);

    const values = {
        sguid: '',
        src: '',
        srcd: '',
        prog: '',
        srcdid: '',
        frm: 'pn',
        assessmentid: 0,
        promptSources: [],
        userSessionGUID: '',
        userSessionID: 0,
    };

    useEffect(() => {
        initializeMarketplace();
    }, [refresh]);

    const initializeMarketplace = () => {
        
        setLoading(true);

        hotjar.initialize(1427423, 6);

        setNumOfAttempts(numOfAttempts + 1);

        const queryString = QueryStringToJSON();

        if (queryString) {

            values.marketplaceSessionGUID = queryString.sguid;
            values.src = queryString.src ? queryString.src : '';
            values.srcd = queryString.srcd ? queryString.srcd : '';
            values.prog = queryString.prog ? queryString.prog : '';
            values.srcdid = queryString.srcdid ? queryString.srcdid : '';
            values.frm = queryString.frm ? queryString.frm : '';
        }

        if (values.sguid) {
            dispatch(MarketplaceSessionGUID(values.sguid));
        }
        else if (GetCookie('marketplaceSessionGUID') != '' && !clearCookie) {
            values.marketplaceSessionGUID = GetCookie('marketplaceSessionGUID');
            dispatch(MarketplaceSessionGUID(values.marketplaceSessionGUID));
        }

        CoreAPI.Initialize_MarketplaceSession(values).then((response) => {
            if (response && response.responseStatus === 'Success') {
                dispatch(UserSessionGUID(response.userSessionGUID));
                dispatch(UserSessionID(response.userSessionID))
                dispatch(MarketplaceSessionGUID(response.marketplaceSessionGUID));
                dispatch(PromptSources(response.promptSources));
            }

            document.cookie = 'marketplaceSessionGUID=' + response.marketplaceSessionGUID + '; path=/;';

            setLoading(false);

            if (values.frm && values.frm.toLowerCase() === 'pe')
                navigate('/login');
            else
                navigate('/register')

            setNumOfAttempts(0);
            setError(false);
            setClearCookie(false);
        }).catch((e) => {

            if (e?.response?.data?.message.toLowerCase() === 'user session expired' || e?.response?.data?.message.toLowerCase() === 'failed to load usersession.') {
                values.marketplaceSessionGUID = '';
                toast.error('User Session has expired.')
                setClearCookie(true);
                setRefresh(!refresh);
            }
            else {
                setError(true);
            }
        });

        return () => {
        };

    };

    const Error = () => {

        const onRetryClick = () => {
            setRefresh(!refresh);
        };

        return (
            <div className='marketplaceFormContainer'>
                <form className='marketplaceForm' style={{ height: '350px' }}>
                    <div className='marketplaceFormContent'>
                        <h3 className='marketplaceFormTitle'>NexRep Contractor Marketplace</h3>
                        <p>
                            Connecting to the Marketplace ...
                        </p>
                        {numOfAttempts >= 5 ?
                            <>
                                <p className='marketplaceFormSubtitleError'>
                                    Error connecting to the Marketplace, please try again
                                </p>
                                <p>
                                    ... or email support at marketplace@nexrep.com.
                                </p>
                                <p>
                                    {numOfAttempts} failed attempt(s)
                                </p>
                            </>
                            :
                            <>
                                <p className='marketplaceFormSubtitleError'>
                                    Error connecting to the Marketplace, please try again.
                                </p>
                                <p>{numOfAttempts} failed attempt(s)</p>
                            </>
                        }

                        <div className='gap-2 mt-3'>
                            <button type='submit' className='marketplaceButton mt-2' onClick={onRetryClick} >
                                Retry
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        );
    };

    return (
        <div>
            <MarketplaceMenu onRefresh={(value) => setRefresh(value)} />
            <div className='marketplaceBGImage'>
                <Container className='marketplaceWorkspace' >
                    {
                        error ?
                            <Error />
                            :
                            <>
                                {!loading ?
                                    <MarketplaceRoutes values={values} />
                                    :
                                    <MarketplaceSpinner hidden={true} />
                                }
                            </>
                    }

                </Container>
            </div>
            {
                isMobile ?
                    null
                    :
                    <footer className='marketplaceFooter' >
                        <div className='copyright'>
                            Copyright &#169; 2023 NexRep&#174;. All Rights Reserved.
                        </div>
                        <div className='links'>
                            <a href='https://nexrep.com/privacy-policy' target='_blank'>Privacy Policy</a>
                            <a className='terms' href='https://nexrep.com/terms' target='_blank'>Terms</a>
                        </div>
                    </footer>
            }

            <ToastContainer autoClose={3000} theme='colored' />
        </div>
    );

}