import PropTypes from 'prop-types';
import { FormControl, TextField } from "@mui/material";
import { Controller } from "react-hook-form";

const FormTextLabel = (props) => {
    
    const {stepDetails, errors, control} = props;

    return (
        <FormControl fullWidth>
            <label className={[stepDetails.promptLabelClass, (stepDetails.isFlex && stepDetails.promptLabelColumns && stepDetails.promptLabelColumns !== '' ? ' col-' + stepDetails.promptLabelColumns : '')]} >{stepDetails.promptLabel}<span className='marketplaceProcessRequired'>{stepDetails.validateRequired && ' *'}</span></label>
            <Controller
                control={control}
                name={stepDetails.referenceValidate}
                defaultValue={stepDetails.fieldValue}
                render={({ field }) => (
                    <TextField
                        {...field}
                        fullWidth
                        id={stepDetails.reference}
                        name={stepDetails.reference}
                        className={stepDetails.promptValueClass}
                        disabled
                        size='small'
                    />
                )}
                rules={{ required: stepDetails.validateRequired }}
            />
            {errors && errors[stepDetails.referenceValidate]?.type === 'required' && (
                <label className='marketplaceProcessRequired'>This field is required.</label>
            )}
        </FormControl>
    );

};

export default FormTextLabel;

FormTextLabel.propTypes = {
    stepDetails: PropTypes.any.isRequired,
    errors: PropTypes.any,
    control: PropTypes.any.isRequired
};

