import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    assessmentCompletedConversionURL: '',
    AssessmentCompletedConversionJS: '',
};

export const FlowSRCReducer = createSlice({
    name: 'FlowSRC',
    initialState,
    reducers: {
        SetAssessmentCompletedConversionURL: (state, action) => {
            state.agentProfileGUID = action.payload.assessmentCompletedConversionURL;
        }, 
        SetAssessmentCompletedConversionJS: (state, action) => {
            state.agentProfileGUID = action.payload.AssessmentCompletedConversionJS;
        },
    }
});

export const { SetAssessmentCompletedConversionURL, SetAssessmentCompletedConversionJS } = FlowSRCReducer.actions;

export default FlowSRCReducer.reducer;