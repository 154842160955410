import axios from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { socratesAPIEndpoint } from '../../components/shared/environment';
import MarketplaceStore from "../../redux/marketplacestore";


const ProcessAPI = ({

  
    Begin:  function (data) {
        try {

            const state = MarketplaceStore.getState();

           return axios({
                url: `${socratesAPIEndpoint}/SocratesProcess/Begin`,
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'UserSessionGUID': state.core.userSessionGUID,
                    'UserSessionID': state.core.userSessionID
                },
                data: JSON.stringify({
                    'processID': data.processID,
                    'processCriteria': data.processCriteria
                })
            }).then(response => response.data);
        }
        catch (error) {
            return error;
        }
    },

    Continue:  function (data, prompValues, nextStepID) {
        try {

            const state = MarketplaceStore.getState();

           return axios({
                url: `${socratesAPIEndpoint}/SocratesProcess/Continue`,
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'UserSessionGUID': state.core.userSessionGUID,
                    'UserSessionID': state.core.userSessionID
                },
                data: JSON.stringify({
                    'processSessionGUID': data.processSessionGUID,
                    'nextStepID': nextStepID,
                    "postedValues": prompValues,
                    'submit': 'No'
                })
            }).then(response => response.data);
        }
        catch (error) {
            return error;
        }
    },


    UploadMedia:  function (processSessionGUID, file, validateFileSizeMax) {
        try {

            const state = MarketplaceStore.getState();

            if (file.length === 0)
                return;

            if (validateFileSizeMax) {

                var fileSizeInMB = file.size / Math.pow(1024, 2);

                if (fileSizeInMB > validateFileSizeMax) {
                    toast.error(`File size must be less than ${validateFileSizeMax} MB.`);
                    return;
                }
            }

            var postData = new FormData();

            postData.append(0, file);
            postData.append("processSessionGUID", processSessionGUID);


           return axios({
                url: `${socratesAPIEndpoint}/SocratesProcess/UploadFile`,
                method: "POST",
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'UserSessionGUID': state.core.userSessionGUID,
                    'UserSessionID': state.core.userSessionID
                },
                data: postData
            }).then(response => response.data);
        }
        catch (error) {
            return error;
        }
    }

});

export default ProcessAPI;



