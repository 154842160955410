import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    assessmentID: 100006,
    intervalTime: 0,
    mediaTime: 0,
    setFormStep: null,
};

export const ProcessReducer = createSlice({
    name: 'Process',
    initialState,
    reducers: {
        setTimer: (state, action) => {
            state.intervalTime = action.payload;
        },
        AssessmentID: (state, action) => {
            state.assessmentID = action.payload;
        },
        MediaTime: (state, action) => {
            state.mediaTime = action.payload;
        },
        SetFormStep: (state, action) => {
            state.setFormStep = action.payload;
        },
    }
});

export const { setTimer, AssessmentID, MediaTime, SetFormStep } = ProcessReducer.actions;

export default ProcessReducer.reducer;