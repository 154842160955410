
import PropTypes from 'prop-types';
import Konva from 'konva';
import { useEffect } from 'react';
import { isMobile } from 'react-device-detect';

const FormInteractiveMap = (props) => {

    const { step, register, setValue, errors, disableValidation } = props;
    let promptValueType = step.stepDetails.PromptValueType;
    let promptLabel = step.stepDetails.PromptLabel;
    let reference = step.stepDetails.PromptType + '.' + step.stepDetails.RecordReference + '.' + (step.stepDetails.PromptType === 'Dataset' ? step.stepDetails.FieldName : step.stepDetails.FieldAttributeID);
    let referenceValidate = step.stepDetails.PromptType + '~' + step.stepDetails.RecordReference + '~' + (step.stepDetails.PromptType === 'Dataset' ? step.stepDetails.FieldName : step.stepDetails.FieldAttributeID);
    let validateRequired = disableValidation ? false : step.stepDetails.ValidateRequired === 'Required' ? true : false;
    let mediaWidth = step.stepDetails.MediaWidth;
    let mediaHeight = step.stepDetails.MediaHeight;
    let mediaSource = step.stepDetails.MediaSource;
    let interactiveImageNumChoices = Number(step.stepDetails.InteractiveImageNumChoices);
    let interactiveImageRegions = step.stepDetails.InteractiveImageRegions;
    let interactiveImageIncorrectResponse = step.stepDetails.InteractiveImageIncorrectResponse;

    useEffect(() => {

        const loadStage = () => {

            //1. Load the stage data from the FieldValue

            let selectedShape = null;
            let hiddenField = document.getElementById(reference);
            let stage = Konva.Node.create(interactiveImageRegions, 'formInteractiveMap_' + reference);
            let scale = 0;

            //2. Set the stage container height, width and background details

            let sceneWidth = mediaWidth;
            let sceneHeight = mediaHeight;

            stage.container().style.width = mediaWidth + 'px';
            stage.container().style.height = mediaHeight + 'px';
            stage.container().style.backgroundImage = 'url(' + mediaSource + ')';
            stage.container().style.backgroundRepeat = 'no-repeat';
            stage.container().style.backgroundSize = + mediaWidth + 'px ' + mediaHeight + 'px';

            Konva.pixelRatio = 1;

            // Add resizeStage function to adjust the scale based on the size of users screen

            function resizeStage() {

                let container = document.getElementById('formInteractiveMap_' + reference);

                let containerWidth = container.offsetWidth;

                scale = containerWidth / sceneWidth;

                stage.width(sceneWidth * scale);
                stage.height(sceneHeight * scale);
                stage.scale({ x: scale, y: scale });

                stage.container().style.width = sceneWidth * scale + 'px';
                stage.container().style.height = sceneHeight * scale + 'px';
                stage.container().style.backgroundSize = + sceneWidth * scale + 'px ' + sceneHeight * scale + 'px';

            }

            if (isMobile) {

                resizeStage();

                window.addEventListener('resize', resizeStage);
            }


            //3. Add a new layer to the stage so we can add the user interaction shapes

            let layer = new Konva.Layer();
            stage.add(layer);

            //4. Disable the context menu on right click

            stage.on('contextmenu', (e) => {
                e.evt.preventDefault();
            });

            if (promptValueType == 'HeatMap') {

                // Add the stage onClick function to create the heatmap circles

                let i = 1;
                let values = [];
                stage.on('click tap', function (e) {

                    if (e.evt.button === 2) {
                        return;
                    }

                    let name = e.target.getAttr('name');

                    if (i > interactiveImageNumChoices) {

                        let circs = stage.find('.circle');
                        let olderCircle = circs.find(x => x === circs[0]);
                        values.splice(0, 1);
                        setValue(referenceValidate, JSON.stringify(values));
                        olderCircle.destroy();
                    }

                    if (name !== 'rect') {
                        if ((!values.includes(interactiveImageIncorrectResponse) && interactiveImageNumChoices === 1) || interactiveImageNumChoices > 1) {
                            values.push(interactiveImageIncorrectResponse);
                            setValue(referenceValidate, JSON.stringify(values));
                        }
                    }
                    else {
                        let value = e.target.getAttr('value');
                        if ((values.indexOf(value) === -1 && interactiveImageNumChoices === 1) || interactiveImageNumChoices > 1) {
                            values.push(value);
                            setValue(referenceValidate, JSON.stringify(values));
                        }
                        else {
                            setValue(referenceValidate, JSON.stringify(values));
                        }
                    }


                    let circle = new Konva.Circle({
                        id: i,
                        name: 'circle',
                        x: stage.getRelativePointerPosition().x,
                        y: stage.getRelativePointerPosition().y,
                        radius: 7,
                        fill: '#007ac0',
                        stroke: '#ffffff',
                        strokeWidth: 1,
                        draggable: false,
                        value: ''
                    });
                    layer.add(circle);
                    i++
                });

                //Find all of the rectangles from the interactive map config and make sure we hide them from the user and also add the click event to indicate if the user's clicked circle touches the rectangle

                let rects = stage.find('.rect');
                rects.forEach(function (rect) {
                    rect.draggable(false);
                    rect.fill(null);
                    rect.strokeWidth(0);
                    rect.on('click tap', function (e) {
                        if (e.evt.button === 2) {
                            return;
                        }
                        if (selectedShape && selectedShape !== rect) {
                            selectedShape = null;
                        }
                        selectedShape = rect;
                        saveRegionValue(selectedShape);
                    });
                });

                function saveRegionValue(selectedShape) {
                    let value = selectedShape.getAttr('value');
                    setValue(referenceValidate, value);
                };


            }
            else {

                //Find all of the rectangles from the interactive map config and make sure we hide them from the user

                let shapes = stage.find('.rect');
                shapes.forEach(function (shape) {
                    shape.draggable(false);
                    shape.fill(null);
                    shape.strokeWidth(0);

                    //Add the mouseover/touchstart event to show shape on hover

                    shape.on('mouseover touchstart', function () {
                        shape.stroke('#000000');
                        shape.strokeWidth(4);
                        stage.container().style.cursor = 'pointer';
                    });

                    //Add the mouseout/touchend event to hide shape on leaving

                    shape.on('mouseout touchend', function () {
                        shape.stroke(null);
                        shape.strokeWidth(0);
                        stage.container().style.cursor = 'default';
                    });

                    // Add click/tap event to show shape selection

                    shape.on('click tap', function (e) {
                        if (e.evt.button === 2) {
                            return;
                        }
                        if (selectedShape && selectedShape !== shape) {
                            selectedShape.fill(null);
                            selectedShape.strokeWidth(0);
                            selectedShape = null;
                        }
                        shape.fill('rgba(0,0,255,0.5)');
                        shape.stroke('#000000');
                        shape.strokeWidth(4);
                        selectedShape = shape;
                        saveRegionValue(selectedShape);
                    });

                });

                //Add the saveRegion function 

                function saveRegionValue(selectedShape) {
                    let value = selectedShape.getAttr('value');
                    setValue(referenceValidate, value);
                };

            }

        };

        if (document.readyState === 'complete') {
            loadStage();
        } else {
            window.addEventListener('load', loadStage);
            return () => window.removeEventListener('load', loadStage);
        }
    }, []);

    return (
        <>
            <label className='marketplaceFontExtraDarkBold' >{promptLabel}<span className='marketplaceProcessRequired'>{validateRequired && ' *'}</span></label>
            <div className='marketplaceProcessInteractiveMap' width={mediaWidth} height={mediaHeight} name={'formInteractiveMap_' + reference} id={'formInteractiveMap_' + reference} />
            <input type='hidden' className='form-control' {...register(referenceValidate, { required: validateRequired })} name={reference} id={reference} />
            {errors && errors[referenceValidate]?.type === 'required' && (
                <span className='marketplaceProcessRequired'>This field is required.</span>
            )}
        </ >
    );
};

export default FormInteractiveMap;

FormInteractiveMap.propTypes = {
    step: PropTypes.any.isRequired,
    register: PropTypes.any,
    errors: PropTypes.any,
    setValue: PropTypes.any,
    disableValidation: PropTypes.any
};
