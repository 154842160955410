import axios from "axios";
import { socratesAPIEndpoint } from '../../components/shared/environment';


const FlowSRCAPI = ({

    ProfileNew: function (data) {

        try {

            return axios({
                url: `${socratesAPIEndpoint}/SocratesMarketplace/ProfileNew`,
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify({
                    'MarketplaceSessionGUID': data.marketplaceSessionGUID,
                    'FirstName': data.firstname,
                    'LastName': data.lastname,
                    'Email': data.email,
                    'Password': data.password,
                    'WebTrafficSourceDetailID': data.webTrafficSourceDetailID,
                    'WebTrafficSourceOther': data.webTrafficSourceOther
                })
            }).then(response => response.data);
        }
        catch (error) {
            return error;
        }
    },
    ProfileNewSetPassword: function (data) {

        try {

            return axios({
                url: `${socratesAPIEndpoint}/SocratesMarketplace/ProfileNewSetPassword`,
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify({
                    'MarketplaceSessionGUID': data.marketplaceSessionGUID,
                    'Email': data.email,
                    'Password': data.password
                })
            }).then(response => response.data);
        }
        catch (error) {
            return error;
        }
    },
    ProfileNewCheckPassword: function (data) {

        try {

            return axios({
                url: `${socratesAPIEndpoint}/SocratesMarketplace/ProfileNewCheckPassword`,
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify({
                    'MarketplaceSessionGUID': data.marketplaceSessionGUID,
                    'Email': data.email,
                    'Password': data.password
                })
            }).then(response => response.data);
        }
        catch (error) {
            return error;
        }
    },
    ProfileExisting: function (data) {

        try {

            return axios({
                url: `${socratesAPIEndpoint}/SocratesMarketplace/ProfileExisting`,
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify({
                    'MarketplaceSessionGUID': data.marketplaceSessionGUID,
                    'Email': data.email,
                    'Password': data.password,
                    'WebTrafficSourceDetailID': data.webTrafficSourceDetailID,
                    'WebTrafficSourceOther': data.webTrafficSourceOther
                })
            }).then(response => response.data);
        }
        catch (error) {
            return error;
        }
    },
    SendPassword: function (data) {

        try {

            return axios({
                url: `${socratesAPIEndpoint}/SocratesMarketplace/SendPassword`,
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify({
                    'MarketplaceSessionGUID': data.marketplaceSessionGUID,
                    'Email': data.email,
                })
            }).then(response => response.data);
        }
        catch (error) {
            return error;
        }
    },
    ResidencyCheck: function (data) {

        try {

            return axios({
                url: `${socratesAPIEndpoint}/SocratesMarketplace/ResidencyCheck`,
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify({
                    'MarketplaceSessionGUID': data.marketplaceSessionGUID,
                    'agentProfileID': data.agentProfileID,
                    'agentProfileGUID': data.agentProfileGUID
                })
            }).then(response => response.data);
        }
        catch (error) {
            return error;
        }
    },
    ResidencyUpdate: function (data) {

        try {

            return axios({
                url: `${socratesAPIEndpoint}/SocratesMarketplace/ResidencyUpdate`,
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify({
                    'MarketplaceSessionGUID': data.marketplaceSessionGUID,
                    'agentProfileID': data.agentProfileID,
                    'agentProfileGUID': data.agentProfileGUID,
                    'country': data.country,
                    'countryOther': data.countryOther,
                    'state': data.state
                })
            }).then(response => response.data);
        }
        catch (error) {
            return error;
        }
    },
    AffirmationCheck: function (data) {

        try {

            return axios({
                url: `${socratesAPIEndpoint}/SocratesMarketplace/AffirmationCheck`,
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify({
                    'MarketplaceSessionGUID': data.marketplaceSessionGUID,
                    'agentProfileID': data.agentProfileID,
                    'agentProfileGUID': data.agentProfileGUID
                })
            }).then(response => response.data);
        }
        catch (error) {
            return error;
        }
    },
    AffirmationUpdate: function (data, affirmation) {

        try {

            return axios({
                url: `${socratesAPIEndpoint}/SocratesMarketplace/AffirmationUpdate`,
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify({
                    'MarketplaceSessionGUID': data.marketplaceSessionGUID,
                    'agentProfileID': data.agentProfileID,
                    'agentProfileGUID': data.agentProfileGUID,
                    'affirmation': affirmation,
                    'affirmationText': data.affirmationText
                })
            }).then(response => response.data);
        }
        catch (error) {
            return error;
        }
    },
    AssessmentCheck: function (data) {

        try {

            return axios({
                url: `${socratesAPIEndpoint}/SocratesMarketplace/AssessmentCheck`,
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify({
                    'MarketplaceSessionGUID': data.marketplaceSessionGUID,
                    'agentProfileID': data.agentProfileID,
                    'agentProfileGUID': data.agentProfileGUID
                })
            }).then(response => response.data);
        }
        catch (error) {
            return error;
        }
    },
    GatewayCheck: function (data) {

        try {

            return axios({
                url: `${socratesAPIEndpoint}/SocratesMarketplace/GatewayCheck`,
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify({
                    'MarketplaceSessionGUID': data.marketplaceSessionGUID,
                    'agentProfileID': data.agentProfileID,
                    'agentProfileGUID': data.agentProfileGUID
                })
            }).then(response => response.data);
        }
        catch (error) {
            return error;
        }
    },
    AgentProfileRead: function (data) {

        try {

            return axios({
                url: `${socratesAPIEndpoint}/SocratesMarketplace/AgentProfileRead`,
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify({
                    'MarketplaceSessionGUID': data.marketplaceSessionGUID,
                    'agentProfileID': data.agentProfileID,
                    'agentProfileGUID': data.agentProfileGUID,
                })
            }).then(response => response.data);
        }
        catch (error) {
            return error;
        }
    },
    AgentProfileUpdate: function (data, languageFluency) {

        try {

            return axios({
                url: `${socratesAPIEndpoint}/SocratesMarketplace/AgentProfileUpdate`,
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify({
                    'MarketplaceSessionGUID': data.marketplaceSessionGUID,
                    'agentProfileID': data.agentProfileID,
                    'agentProfileGUID': data.agentProfileGUID,
                    'firstName': data.firstName,
                    'lastName': data.lastName,
                    'address1': data.address1,
                    'address2': data.address2,
                    'city': data.city,
                    'state': data.state,
                    'zip': data.zip,
                    'homePhone': data.homePhone,
                    'cellPhone': data.cellPhone,
                    'smsOptIn': data.smsOptIn,
                    'email': data.email,
                    'education': data.education,
                    'languageFluency': languageFluency,
                    'contactCenterPhoneSupport': data.contactCenterPhoneSupport === true ? 'Yes' : 'No',
                    'contactCenterChatSupport': data.contactCenterChatSupport ? 'Yes' : 'No',
                    'contactCenterEmailSupport': data.contactCenterEmailSupport ? 'Yes' : 'No'
                })
            }).then(response => response.data);
        }
        catch (error) {
            return error;
        }
    },
});

export default FlowSRCAPI;