import React, { useState, useEffect, Fragment } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { hotjar } from 'react-hotjar';
import $ from 'jquery';

import ProcessAPI from '../../api/process/processapi';
import FormText from '../../components/process/prompttypes/formtext';
import FormTextLabel from '../../components/process/prompttypes/formtextlabel';
import FormSelect from '../../components/process/prompttypes/formselect';
import FormHTMLEditor from '../../components/process/prompttypes/formhtmleditor';
import FormRadio from '../../components/process/prompttypes/formradio';
import FormCheckbox from '../../components/process/prompttypes/formcheckbox';
import FormSortable from '../../components/process/prompttypes/formsortable';
import FormTimer from '../../components/process/prompttypes/formtimer';
import FormAudioRecorder from '../../components/process/prompttypes/formaudiorecorder';
import FormVideoRecorder from '../../components/process/prompttypes/formvideorecorder';
import FormMatrix from '../../components/process/prompttypes/formmatrix';
import FormLabel from '../../components/process/steptypes/formlabel';
import FormAudio from '../../components/process/steptypes/formaudio';
import FormVideo from '../../components/process/steptypes/formvideo';
import FormImage from '../../components/process/steptypes/formimage';
import FormHTML from '../../components/process/steptypes/formhtml';
import FormInteractiveMap from '../../components/process/steptypes/forminteractivemap';
import FormBestWorst from '../../components/process/prompttypes/formbestworst';
import MarketplaceSpinner from '../../components/shared/marketplacespinner/marketplacespinner';
import { ShowMarketplaceError } from '../../utils/core/coreutil';
import { useDispatch } from 'react-redux';
import { SetFormStep } from '../../redux/process/processreducer';

const Process = ({ props }) => {

    const [processUI, setProcessUI] = useState(props.processUI);
    const formSendStep = useSelector((state) => state.process.setFormStep);
    const navigate = useNavigate();
    const [data, setData] = useState({
        userSessionGUID: props.userSessionGUID,
        userSessionID: props.userSessionID,
        processSessionGUID: props.processSessionGUID
    });
    const { control, reset, register, handleSubmit, getValues, setValue, formState: { errors } } = useForm();
    const [disableValidation, setDisableValidation] = useState(false);
    const [lock, setLock] = useState(false);
    const dispatch = useDispatch();
    let MatrixHeaders = [];

    useEffect(() => {

        if (hotjar.initialized()) {
            hotjar.stateChange('/profileexisting');
        }

        window.addEventListener('popstate', function (event) {
            window.history.pushState(null, document.title, window.location.href);
        });
        window.addEventListener('contextmenu', handelRightClick);

        let processBusy = document.getElementById('divProcessBusy');
        if (processBusy)
            processBusy.style.display = 'none';

        $('#marketplaceProcess-body').removeClass('hidden');
        $('#marketplaceProcess-body').addClass('marketplaceProcess-body');
        $('#marketplaceProcess-body').scrollTop(0);

        let step = processUI.find(value => value.stepType.includes('FormSend'));
        if (step && step.stepDetails?.FormOnLoad.toLowerCase() === 'close') {
            dispatch(SetFormStep(null))
            navigate('/gateway');
        }
        else if (step)
            dispatch(SetFormStep(step))

        reset();

    }, [processUI]);

    const GetForm = ({ step }) => {

        let stepType = step.stepType.toLowerCase();

        let divClass = '';

        if (step.stepDetails.LayoutColumns !== '0')
            divClass += ' col-' + step.stepDetails.LayoutColumns;

        if (step.stepDetails.LayoutPadding !== '0')
            divClass += ' offset-' + step.stepDetails.LayoutPadding;

        if (step.stepDetails.PromptLabelAlignment == null || step.stepDetails.PromptLabelAlignment !== 'Left')
            divClass += ' form-col';
        else
            divClass += ' marketplaceProcess-colflex';

        if (step.stepDetails.ValidateRequired && step.stepDetails.ValidateRequired.toLowerCase() === ' required')
            divClass += ' required';

        return (
            <div className={divClass} >
                {(() => {
                    switch (stepType) {
                        case 'formlabel':
                            return (
                                <FormLabel step={step} />
                            );
                        case 'formprompt':
                            return (
                                <FormPrompt step={step} />
                            );
                        case 'video':
                            return (
                                <FormVideo step={step} />
                            );
                        case 'audio':
                            return (
                                <FormAudio step={step} />
                            );
                        case 'image':
                            return (
                                <FormImage step={step} />
                            );
                        case 'formhtml':
                            return (
                                <FormHTML step={step} />
                            );
                        case 'interactivemap':
                            return (
                                <FormInteractiveMap
                                    step={step}
                                    register={register}
                                    setValue={setValue}
                                    errors={errors}
                                    disableValidation={disableValidation}
                                />
                            );
                        default:
                            return (
                                <label className='errorMessage'>Rendering Error for StepType {stepType} </label>
                            );
                    }
                })()}
            </div>
        );

    };

    const FormPrompt = ({ step }) => {

        const stepDetails = {
            reference: step.stepDetails.PromptType + '.' + step.stepDetails.RecordReference + '.' + (step.stepDetails.PromptType === 'Dataset' ? step.stepDetails.FieldName : step.stepDetails.FieldAttributeID),
            referenceValidate: step.stepDetails.PromptType + '~' + step.stepDetails.RecordReference + '~' + (step.stepDetails.PromptType === 'Dataset' ? step.stepDetails.FieldName : step.stepDetails.FieldAttributeID),
            referenceJquery: step.stepDetails.PromptType + step.stepDetails.RecordReference + (step.stepDetails.PromptType === 'Dataset' ? step.stepDetails.FieldName : step.stepDetails.FieldAttributeID),
            field: step.stepDetails.PromptType === 'Dataset' ? step.stepDetails.FieldName : step.stepDetails.FieldAttributeID,
            fieldValue: step.stepDetails.FieldValue,
            fieldJSON: step.stepDetails.fieldJSON,
            layoutColumns: step.stepDetails.LayoutColumns,
            promptLabel: step.stepDetails.PromptLabel,
            promptValueType: step.stepDetails.PromptValueType,
            promptLabelClass: step.stepDetails.PromptLabelClass,
            promptLabelColumns: step.stepDetails.PromptLabelColumns,
            promptLabelAlignment: step.stepDetails.PromptLabelAlignment,
            promptValueClass: step.stepDetails.PromptValueClass,
            promptValueColumns: step.stepDetails.PromptValueColumns,
            validateRequired: disableValidation ? false : step.stepDetails.ValidateRequired === 'Required' ? true : false,
            validateFileSizeMax: step.stepDetails.ValidateFileSizeMax,
            selectValues: step.stepDetails.SelectValues,
            formTimed: step.stepDetails.FormTimed,
            formTimerType: step.stepDetails.FormTimerType,
            formTimerTime: step.stepDetails.FormTimerTime,
            formTimerOnEnd: step.stepDetails.FormTimerOnEnd,
            mediaRetake: step.stepDetails.MediaRetake,
            isFlex: false,
            skipHeaders: false
        };

        if (stepDetails.promptLabelAlignment)
            stepDetails.isFlex = true;

        if (stepDetails.promptLabelClass === '' || stepDetails.promptLabelClass === null)
            stepDetails.promptLabelClass = 'socratesFontExtraDarkBold';

        if (stepDetails.promptValueClass === '' || stepDetails.promptValueClass === null)
            stepDetails.promptValueClass = 'socratesFontExtraDark';

        if (stepDetails.promptValueType.toLowerCase() === 'matrix') {

            let matrixHeaders = [];
            for (let i = 0; i < stepDetails.selectValues.length; i++) {
                matrixHeaders.push(stepDetails.selectValues[i].Label);
            }

            if (MatrixHeaders.length > 0) {

                if (JSON.stringify(MatrixHeaders) === JSON.stringify(matrixHeaders))
                    stepDetails.skipHeaders = true;
                else {
                    MatrixHeaders = stepDetails.selectValues;
                }

            }
            else {
                for (let i = 0; i < stepDetails.selectValues.length; i++) {
                    MatrixHeaders.push(stepDetails.selectValues[i].Label);
                }
            }

        }

        const FormPromptControl = () => {

            switch (stepDetails.promptValueType.toLowerCase()) {
                case 'label':
                    return (
                        <FormTextLabel
                            stepDetails={stepDetails}
                            errors={errors}
                            control={control}
                        />
                    );
                case 'text':
                case 'textarea':
                    return (
                        <FormText
                            stepDetails={stepDetails}
                            errors={errors}
                            control={control} />
                    );
                case 'html-editor':
                    return (
                        <FormHTMLEditor
                            stepDetails={stepDetails}
                            errors={errors}
                            control={control}
                            setValue={setValue} />
                    );
                case 'select':
                case 'multi-select':
                    return (
                        <FormSelect
                            stepDetails={stepDetails}
                            errors={errors}
                            control={control} />
                    );
                case 'radio':
                    return (
                        <FormRadio
                            stepDetails={stepDetails}
                            errors={errors}
                            register={register} />
                    );
                case 'checkbox':
                    return (
                        <FormCheckbox
                            stepDetails={stepDetails}
                            errors={errors}
                            register={register} />
                    );
                case 'sortable':
                    return (
                        <FormSortable
                            stepDetails={stepDetails}
                            register={register} />
                    );
                case 'timer':
                    return (
                        <FormTimer
                            stepDetails={stepDetails}
                            errors={errors}
                            register={register}
                            onDisable={(validation) => { setDisableValidation(validation) }}
                        />
                    );
                case 'audio-recorder':
                    return (
                        <FormAudioRecorder
                            processSessionGUID={data.processSessionGUID}
                            stepDetails={stepDetails}
                            errors={errors}
                            register={register} />
                    );
                case 'video-recorder':
                    return (
                        <FormVideoRecorder
                            processSessionGUID={data.processSessionGUID}
                            stepDetails={stepDetails}
                            errors={errors}
                            register={register} />
                    );
                case 'matrix':
                    return (
                        <FormMatrix
                            stepDetails={stepDetails}
                            errors={errors}
                            register={register}
                            getValues={getValues} />
                    );
                case 'best / worst':
                    return (
                        <FormBestWorst
                            stepDetails={stepDetails}
                            errors={errors}
                            {...register(stepDetails.referenceValidate, { required: stepDetails.validateRequired })}
                            setValue={setValue} />
                    );
                default:
                    return (
                        <label className='errorMessage'>Not sure how to build PromptType ... {stepDetails.promptValueType}</label>
                    );
            }

        };
        return (

            <div className={'form-group' + (stepDetails.isFlex ? ' marketplaceProcess-flex' : '')}>
                <FormPromptControl />
            </div>
        );

    };

    const FormButton = ({ buttonLabel, buttonClass, buttonAction, buttonNextStepID, calledFrom }) => {

        if (buttonLabel === '' || buttonLabel === null)
            return '';

        if (buttonClass === '' || buttonClass === null)
            buttonClass = 'marketplaceProcessButton';

        if (buttonAction.toLowerCase() === 'close') {
            return (
                <>
                    <button type='submit' id={buttonNextStepID} variant='contained' onClick={onCloseClick} className='marketplaceButton'>{buttonLabel}</button>
                </>
            )
        }
        else if (buttonAction.toLowerCase() === 'cancel') {
            return (
                <>
                    <button type='submit' id={buttonNextStepID} variant='contained' onClick={onCloseClick} className='marketplaceButton'>{buttonLabel}</button>
                </>
            )
        }
        else if (buttonAction.toLowerCase().includes('bookmark')) {
            return (
                <>
                    <button disabled={lock} type='submit' id={buttonNextStepID} variant='contained' onClick={handleSubmit((data) => { ProcessFormPost(data, buttonNextStepID) })} className='marketplaceButton'>{buttonLabel}</button>
                </>
            )
        }


    };

    const RenderFormButtons = () => {

        if (formSendStep) {

            let buttonDetails = formSendStep.stepDetails;

            return (
                <div>
                    <FormButton buttonNextStepID={buttonDetails.Button1NextStepID} buttonLabel={buttonDetails.Button1Label} buttonClass={buttonDetails.Button1Class} buttonAction={buttonDetails.Button1Action} />
                    <FormButton buttonNextStepID={buttonDetails.Button2NextStepID} buttonLabel={buttonDetails.Button2Label} buttonClass={buttonDetails.Button2Class} buttonAction={buttonDetails.Button2Action} />
                    <FormButton buttonNextStepID={buttonDetails.Button3NextStepID} buttonLabel={buttonDetails.Button3Label} buttonClass={buttonDetails.Button3Class} buttonAction={buttonDetails.Button3Action} />
                    <FormButton buttonNextStepID={buttonDetails.Button4NextStepID} buttonLabel={buttonDetails.Button4Label} buttonClass={buttonDetails.Button3Class} buttonAction={buttonDetails.Button4Action} />
                    <FormButton buttonNextStepID={buttonDetails.Button5NextStepID} buttonLabel={buttonDetails.Button5Label} buttonClass={buttonDetails.Button5Class} buttonAction={buttonDetails.Button5Action} />
                    <FormButton buttonNextStepID={buttonDetails.Button6NextStepID} buttonLabel={buttonDetails.Button6Label} buttonClass={buttonDetails.Button6Class} buttonAction={buttonDetails.Button6Action} />
                </div>
            );
        }

        return null;

    };

    const ProcessFormPost = (postData, buttonNextStepID) => {

        let postedValues = {};

        let array = $('#marketplaceProcess').serializeArray();

        $.each(array, function () {


            let name = '';
            let value = '';

            if (this.name.includes('~|~')) {
                name = this.name.split('~|~')[0];
                value = this.value;
            }
            else if (this.name.includes('~')) {
                name = this.name.replace(/~/g, '.');
                value = this.value;
            }
            else {
                name = this.name;
                value = this.value;
            }


            if (!postedValues.hasOwnProperty(name)) {

                postedValues[name] = value.trim() || '';

            } else {

                if (!Array.isArray(postedValues[name])) {
                    postedValues[name] = [postedValues[name], value.trim() || '']
                } else {
                    postedValues[name].push((value.trim() || ''));
                }

            }

        });

        setLock(true);

        ProcessAPI.Continue(data, postedValues, buttonNextStepID).then((response) => {

            setData({
                ...data,
                processSessionGUID: response.processSessionGUID
            })

            setProcessUI(response.processUI);
            setDisableValidation(false);
            setLock(false);

        }).catch((e) => {

            if (e?.response?.data?.message.toLowerCase() === 'user session expired' || e?.response?.data?.message.toLowerCase() === 'failed to load usersession.') {
                setLock(false);
                navigate(0)
            }
            else {
                ShowMarketplaceError(e.message, 'process.js', 'ProcessFormPost');
                setLock(false);
            }
        });

    };

    String.prototype.replaceAll = function (search, replace) {
        if (replace === undefined) {
            return this.toString();
        }
        return this.split(search).join(replace);
    };

    function getFormSize() {

        if (processUI.length > 0 && formSendStep) {

            let formHeight = parseInt(formSendStep.stepDetails.FormHeight) + 15;
            let formWidth = parseInt(formSendStep.stepDetails.FormWidth) + 30

            return { height: formHeight + 'px', width: formWidth + 'px' }
        }
        else {
            return { height: '400px', width: '500px' }
        }

    };

    const onCloseClick = () => {
        navigate('/gateway');
    };

    const handelRightClick = e => {
        e.preventDefault();
    }

    let rowKey = 0;
    let openRow = false;
    let formRow = [];

    return (
        <div className='marketplaceProcessContainer'>
            <div className='marketplaceProcessHeaderContainer'>
                <div className='marketplaceProcessHeader'>
                    <div className='marketPlaceProceessTitle'>
                        {formSendStep ? formSendStep.stepDetails.FormTitle : ''}
                    </div>
                </div>
            </div>
            <div id='marketplaceProcess-body' className='marketplaceProcess-body' style={getFormSize()}>
                {processUI && processUI.length > 0 ?
                    <Fragment>
                        <form id='marketplaceProcess' className='marketplaceProcess '>
                            <div id='marketplaceProcessContent' className='marketplaceProcessContent'>
                                {!processUI || processUI.length === 0 ?
                                    null
                                    :
                                    <Fragment>
                                        {
                                            processUI.map((step, index) => {

                                                if (step.stepType.toLowerCase() !== 'formsend') {

                                                    //1. Open the row if we do not have one ready on the form

                                                    if (!openRow) {
                                                        openRow = true;
                                                        formRow = [];
                                                    }

                                                    //2. Add the form control to the row array.

                                                    formRow.push(<GetForm key={index} step={step} />);

                                                    //3. If new line, close the row and return the UI

                                                    if (step.stepDetails.LayoutBreak === 'NewLine') {

                                                        rowKey++;
                                                        openRow = false;

                                                        return (
                                                            <div key={rowKey} className={'row mt-2 ' + (step.stepDetails.PromptLabelAlignment !== null
                                                                && step.stepDetails?.PromptLabelAlignment === 'Left'
                                                                && (step.stepDetails?.PromptValueType?.toLowerCase() === 'radio'
                                                                    || step.stepDetails.PromptValueType?.toLowerCase() === 'checkbox') ?
                                                                ' marketplaceProcess-row-no-gutters'
                                                                : '')}>
                                                                {formRow}
                                                            </div>
                                                        );
                                                    }

                                                }
                                            })
                                        }
                                    </Fragment>
                                }
                            </div>
                        </form>
                    </Fragment>
                    :
                    <MarketplaceSpinner hidden={false} />
                }
            </div>
            <div id='divProcessBusy' style={getFormSize()} className='marketplaceProcessBusy marketplaceBackgroundLight'></div>
            <div className='marketplaceProcessFooter'>
                <RenderFormButtons />
            </div>
        </div>
    );

}

export default Process;