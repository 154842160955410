
import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionSummary, AccordionDetails, Radio, RadioGroup } from '@mui/material';
import { sanitize } from 'dompurify';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { BrowserView, MobileView, isFirefox } from 'react-device-detect';

const FormMatrix = (props) => {

    const { stepDetails, getValues, errors, register } = props;
    const [selectedValue, setSelectedValue] = useState('');
    const [selectedLabel, setSelectedLabel] = useState('');


    useEffect(() => {
        let value = getValues(stepDetails.referenceValidate);
        let label = stepDetails.selectValues.filter(function (item) { return item.Value === value; });
        if (label.length > 0) {
            setSelectedValue(label[0].Value);
            setSelectedLabel(label[0].Label);
        }
    }, [])
    const isChecked = (source) => {
        if (source?.Value?.toLowerCase() === stepDetails?.fieldValue?.toLowerCase() || source?.Selected?.toLowerCase() === 'yes') {
            return true;
        }
    };

    const radioOnClick = (event) => {
        setSelectedValue(event.target.value);
    };

    const mobileRadioClick = (event) => {
        setSelectedValue(event.target.value);

        let label = stepDetails.selectValues.filter(function (item) { return item.Value === event.target.value; });
        if (label.length > 0) {
            setSelectedValue(label[0].Value);
            setSelectedLabel(label[0].Label);
        }

    };

    return (
        <>
            <BrowserView>
                <table className='marketplaceMatrix'>
                    {stepDetails.skipHeaders ?
                        <></>
                        :
                        <thead>
                            <tr>
                                <th className='headerTop' width={isFirefox ? '45%' : '55%'}></th>
                                {stepDetails.selectValues.map((value, valueIndex) => (
                                    <th key={valueIndex} className='headerTop' dangerouslySetInnerHTML={{ __html: sanitize(value.Label) }}></th>
                                ))}
                            </tr>
                        </thead>
                    }
                    <tbody>
                        <tr className='tableRow'>
                            <th className='headerLeft'>{stepDetails.promptLabel}</th>
                            {stepDetails.selectValues.map((value, valueIndex) => (
                                <>
                                    {
                                        <td key={valueIndex}>
                                            <input
                                                type='radio'
                                                onClick={radioOnClick}
                                                name={stepDetails.reference}
                                                id={stepDetails.reference + valueIndex}
                                                key={valueIndex}
                                                checked={isChecked(value)}
                                                {...register(stepDetails.referenceValidate, { required: stepDetails.validateRequired })}
                                                value={value.Value}
                                                label={value.Label} />
                                        </td>
                                    }
                                </>
                            ))}
                        </tr>
                    </tbody>
                </table>
                {errors && errors[stepDetails.referenceValidate]?.type === "required" && (
                    <label className="marketplaceProcessRequired">This field is required.</label>
                )}
            </BrowserView>
            <MobileView>
                <Accordion className='marketplaceMatrixMobile'>
                    <AccordionSummary expandIcon={<ExpandMore fontSize='large' style={{ paddingLeft: '5px' }} />}>
                        <div className='marketplaceMatrixMobileAccordionTitle'>
                            <div className={stepDetails.promptLabelClass}>
                                {stepDetails.promptLabel}
                            </div>
                            <div className='marketplaceMatrixMobileAccordionSubtitle'>
                                {errors && errors[stepDetails.referenceValidate]?.type === "required" ?
                                    <label className="marketplaceProcessRequired">This field is required.</label>
                                    :
                                    <label>{selectedLabel}</label>
                                }
                            </div>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        {stepDetails.selectValues.map((value, valueIndex) => (
                            <div key={valueIndex} className='form-check marketplaceProcessRadioButtonFieldSet mobile'>
                                <input
                                    type='radio'
                                    onClick={mobileRadioClick}
                                    name={stepDetails.reference}
                                    id={stepDetails.reference + valueIndex}
                                    key={valueIndex}
                                    checked={isChecked(value)}
                                    {...register(stepDetails.referenceValidate, { required: stepDetails.validateRequired })}
                                    value={value.Value} />
                                <label dangerouslySetInnerHTML={{ __html: sanitize(value.Label) }}></label>
                            </div>
                        ))}
                    </AccordionDetails>
                </Accordion>
            </MobileView>
        </>
    );
};

export default FormMatrix;

FormMatrix.propTypes = {
    stepDetails: PropTypes.any.isRequired,
    getValues: PropTypes.any,
    errors: PropTypes.any,
    register: PropTypes.any
};