import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom'
import { isMobile } from 'react-device-detect';

import { ShowMarketplaceError } from '../../utils/core/coreutil';
import FlowSRCAPI from '../../api/flowsrc/flowsrcapi';
import { FormatScheduleDate, FormatTime, ValidateForm } from '../../utils/library/libraryutil';
import ScheduleAPI from '../../api/schedule/scheduleapi';
import MarketplaceSpinner from '../../components/shared/marketplacespinner/marketplacespinner';

const ScheduleConfirm = (props) => {
    const location = useLocation()
    const marketplaceSessionGUID = useSelector((state) => state.core.marketplaceSessionGUID);
    const agentProfileGUID = useSelector((state) => state.core.agentProfileGUID);
    const agentProfileID = useSelector((state) => state.core.agentProfileID);
    const [data, setData] = useState({
        marketplaceSessionGUID: marketplaceSessionGUID,
        agentProfileGUID: agentProfileGUID,
        agentProfileID: agentProfileID,
        startDate: location.state.startDate,
        scheduledDate: '',
        scheduledTime: '',
        scheduleInterval: location.state.scheduleInterval,
        timeZone: location.state.timeZone,
        existingSourcingScheduleID: location.state.existingSourcingScheduleID ? location.state.existingSourcingScheduleID : 0,
        firstName: '',
        lastName: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        email: '',
        homePhone: '',
        cellPhone: '',
        smsOptIn: '',
        languageFluency: '',
        education: '',
        contactCenterPhoneSupport: false,
        contactCenterChatSupport: false,
        contactCenterEmailSupport: false,
    });
    const [checkedFields, setCheckFields] = useState({
        contactCenterPhoneSupport: false,
        contactCenterChatSupport: false,
        contactCenterEmailSupport: false,
        french: false,
        spanish: false
    });
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [formHeader, setFormHeader] = useState('');
    const [lock, setLock] = useState(false);

    useEffect(() => {
        populateAgentProfile();
        scheduleConfirmOnLoad();
    }, []);

    const scheduleConfirmOnLoad = () => {
        let startDate = new Date(data.startDate);
        let scheduledDate = new Date(startDate);
        let dayPart = parseInt(data.scheduleInterval.substr(0, 1), 10);
        scheduledDate.setDate(scheduledDate.getDate() + dayPart);
        let ScheduledDate = FormatScheduleDate(scheduledDate, 4);
        let ScheduledTime = data.scheduleInterval.substr(1, 2) + ":" + data.scheduleInterval.substr(3);
        setFormHeader(ScheduledDate + " at  " + FormatTime(ScheduledTime) + " (" + data.timeZone + ")");
        setData(values => {
            return {
                ...values,
                scheduledDate: ScheduledDate,
                scheduledTime: FormatTime(ScheduledTime),
            }
        });
    };

    const populateAgentProfile = () => {
        FlowSRCAPI.AgentProfileRead(data).then((response) => {

            let agentProfleStatus = response;

            switch (agentProfleStatus.responseStatus.toLowerCase()) {
                case 'success':

                    let additonalLanguages = [];
                    let languages = agentProfleStatus.languageFluency.split('~');

                    for (var i = 0; i < languages.length; i++) {
                        if (languages[i].toLowerCase() === 'french')
                            setCheckFields(values => {
                                return {
                                    ...values,
                                    french: true,
                                }
                            });
                        else if (languages[i].toLocaleLowerCase() === 'spanish')
                            setCheckFields(values => {
                                return {
                                    ...values,
                                    spanish: true,
                                }
                            });
                        else
                            additonalLanguages.push(languages[i]);
                    }

                    setData(values => {
                        return {
                            ...values,
                            firstName: agentProfleStatus.firstName,
                            lastName: agentProfleStatus.lastName,
                            address1: agentProfleStatus.address1,
                            address2: agentProfleStatus.address2,
                            city: agentProfleStatus.city,
                            state: agentProfleStatus.state,
                            zip: agentProfleStatus.zip,
                            email: agentProfleStatus.email,
                            homePhone: agentProfleStatus.homePhone,
                            cellPhone: agentProfleStatus.cellPhone,
                            smsOptIn: agentProfleStatus.smsOptIn,
                            languageFluency: agentProfleStatus.languageFluency,
                            education: agentProfleStatus.education,
                            contactCenterPhoneSupport: agentProfleStatus.contactCenterPhoneSupport === 'Yes' ? true : false,
                            contactCenterChatSupport: agentProfleStatus.contactCenterChatSupport === 'Yes' ? true : false,
                            contactCenterEmailSupport: agentProfleStatus.contactCenterEmailSupport === 'Yes' ? true : false,
                            additonalLanguages: additonalLanguages
                        }
                    });
                    setCheckFields(values => {
                        return {
                            ...values,
                            contactCenterPhoneSupport: agentProfleStatus.contactCenterPhoneSupport === 'Yes' ? true : false,
                            contactCenterChatSupport: agentProfleStatus.contactCenterChatSupport === 'Yes' ? true : false,
                            contactCenterEmailSupport: agentProfleStatus.contactCenterEmailSupport === 'Yes' ? true : false,
                        }
                    });
                    break;
                default:
                    toast.error(agentProfleStatus);
            }
            setLoading(false);
        }).catch((e) => {
            if (e?.response?.data?.message.toLowerCase() === 'user session expired' || e?.response?.data?.message.toLowerCase() === 'failed to load usersession.') {
                setLoading(false);
                navigate(0)
            }
            else
                ShowMarketplaceError(e.message, 'scheduleconfirm.js', 'scheduleConfirmOnLoad()');
        });
    };

    const scheduleConfirmClick = (e) => {

        e.preventDefault();

        let errorMessage = ValidateForm(data);

        if (errorMessage !== '') {
            errorMessage = 'The following field(s) are required: ' + errorMessage.substring(2);
            toast.error(errorMessage);
            return;
        }

        let languageFluency = '';

        if (checkedFields.french)
            languageFluency += '~French';

        if (checkedFields.spanish)
            languageFluency += '~Spanish';

        if (data.additonalLanguages)
            languageFluency += '~' + data.additonalLanguages;

        languageFluency = languageFluency.substring(1);

        setData(values => {
            return {
                ...values,
                languageFluency: languageFluency
            }
        });

        setLock(true);
        ScheduleAPI.ScheduleConfirm(data, languageFluency).then((response) => {

            let scheduleConfirmStatus = response;

            switch (scheduleConfirmStatus.responseStatus.toLowerCase()) {
                case 'success':
                    navigate('/gateway');
                    break;
                case 'failed: not available':
                    toast.error('This appointment time is no longer available. Please click the Cancel button and select another time.');
                    break;
                case 'failed: residency':
                    toast.error('Based on your residency, Marketplace opportunites are not available to you at this time.');
                    break;
                default:
                    toast.error(scheduleConfirmStatus);
            }
            setLoading(false);
            setLock(false);

        }).catch((e) => {
            if (e?.response?.data?.message.toLowerCase() === 'user session expired' || e?.response?.data?.message.toLowerCase() === 'failed to load usersession.') {
                navigate(0)
            }
            else
                ShowMarketplaceError(e.message, 'scheduleconfirm.js', 'scheduleConfirmOnLoad()');
            setLock(false);
        });

    };


    const handleInputChange = (e) => {
        e.preventDefault();
        setData(prevState => {
            return { ...prevState, [e.target.name]: e.target.value }
        });
    };

    const handleCheckFields = (e) => {
        setCheckFields(prevState => {
            return { ...prevState, [e.target.name]: e.target.checked }
        });
        setData(prevState => {
            return { ...prevState, [e.target.name]: e.target.value }
        });
    };

    const onCancelClick = () => {
        navigate('/scheduleavailability');
    };

    return (
        <>
            {!loading ?
                <div className='marketplaceFormContainer'>
                    <form className='marketplaceForm' style={{ height: '800px' }}>
                        <div className='marketplaceFormContent'>
                            <h3 className='marketplaceFormTitle'>NexRep Contractor Marketplace</h3>
                            <p>To schedule your call with a Marketplace Coordinator for <b style={{ color: 'red' }}>{formHeader}</b>, please verify your contact information and click Confirm.</p>
                            <div className={'form-group ' + (isMobile ? '' : 'marketplaceInline50')}>
                                <label className='marketplaceFormFieldLabel'>First Name</label>
                                <span className='marketplaceFormFieldLabelRequired'> *</span>
                                <TextField
                                    required
                                    fullWidth
                                    name='firstname'
                                    type='firstname'
                                    className='mt-1 '
                                    value={data.firstName}
                                    onChange={(e) => { handleInputChange(e) }}
                                    size='small'
                                />
                            </div>
                            <div className={'form-group ' + (isMobile ? '' : 'marketplaceInline50')}>
                                <label className='marketplaceFormFieldLabel'>Last Name</label>
                                <span className='marketplaceFormFieldLabelRequired'> *</span>
                                <TextField
                                    required
                                    fullWidth
                                    name='lastName'
                                    type='lastName'
                                    className='mt-1 '
                                    value={data.lastName}
                                    onChange={(e) => { handleInputChange(e) }}
                                    size='small'
                                />
                            </div>

                            <div className={'form-group ' + (isMobile ? '' : 'marketplaceInline50')}>
                                <label className='marketplaceFormFieldLabel'>Address 1</label>
                                <span className='marketplaceFormFieldLabelRequired'> *</span>
                                <TextField
                                    required
                                    fullWidth
                                    name='address1'
                                    type='address1'
                                    className='mt-1'
                                    value={data.address1}
                                    onChange={(e) => { handleInputChange(e) }}
                                    size='small'
                                />
                            </div>
                            <div className={'form-group ' + (isMobile ? '' : 'marketplaceInline50')}>
                                <label className='marketplaceFormFieldLabel'>Address 2</label>
                                <TextField
                                    fullWidth
                                    name='address2'
                                    type='address2'
                                    className='mt-1'
                                    value={data.address2}
                                    onChange={(e) => { handleInputChange(e) }}
                                    size='small'
                                />
                            </div>

                            <div className={'form-group ' + (isMobile ? '' : 'marketplaceInline50')}>
                                <label className='marketplaceFormFieldLabel'>City</label>
                                <span className='marketplaceFormFieldLabelRequired'> *</span>
                                <TextField
                                    required
                                    fullWidth
                                    name='city'
                                    type='city'
                                    className='mt-1'
                                    value={data.city}
                                    onChange={(e) => { handleInputChange(e) }}
                                    size='small'
                                />
                            </div>
                            <div className={'form-group ' + (isMobile ? '' : 'marketplaceInline25')}>
                                <label className='marketplaceFormFieldLabel'>State</label>
                                <span className='marketplaceFormFieldLabelRequired'> *</span>
                                <Select
                                    required
                                    fullWidth
                                    name='state'
                                    className='mt-1'
                                    value={data.state}
                                    defaultValue=''
                                    onChange={(e) => { handleInputChange(e) }}
                                    MenuProps={{
                                        style: {
                                            maxHeight: 400,
                                        },
                                    }}
                                    size='small'>
                                    <MenuItem value=''></MenuItem>
                                    <MenuItem value='AK'>AK</MenuItem>
                                    <MenuItem value='AL'>AL</MenuItem>
                                    <MenuItem value='AR'>AR</MenuItem>
                                    <MenuItem value='AZ'>AZ</MenuItem>
                                    <MenuItem value='CA'>CA</MenuItem>
                                    <MenuItem value='CO'>CO</MenuItem>
                                    <MenuItem value='CT'>CT</MenuItem>
                                    <MenuItem value='DC'>DC</MenuItem>
                                    <MenuItem value='DE'>DE</MenuItem>
                                    <MenuItem value='FL'>FL</MenuItem>
                                    <MenuItem value='GA'>GA</MenuItem>
                                    <MenuItem value='HI'>HI</MenuItem>
                                    <MenuItem value='IA'>IA</MenuItem>
                                    <MenuItem value='ID'>ID</MenuItem>
                                    <MenuItem value='IL'>IL</MenuItem>
                                    <MenuItem value='IN'>IN</MenuItem>
                                    <MenuItem value='KS'>KS</MenuItem>
                                    <MenuItem value='KY'>KY</MenuItem>
                                    <MenuItem value='LA'>LA</MenuItem>
                                    <MenuItem value='MA'>MA</MenuItem>
                                    <MenuItem value='MD'>MD</MenuItem>
                                    <MenuItem value='ME'>ME</MenuItem>
                                    <MenuItem value='MI'>MI</MenuItem>
                                    <MenuItem value='MN'>MN</MenuItem>
                                    <MenuItem value='MO'>MO</MenuItem>
                                    <MenuItem value='MS'>MS</MenuItem>
                                    <MenuItem value='MT'>MT</MenuItem>
                                    <MenuItem value='NC'>NC</MenuItem>
                                    <MenuItem value='ND'>ND</MenuItem>
                                    <MenuItem value='NE'>NE</MenuItem>
                                    <MenuItem value='NH'>NH</MenuItem>
                                    <MenuItem value='NJ'>NJ</MenuItem>
                                    <MenuItem value='NM'>NM</MenuItem>
                                    <MenuItem value='NV'>NV</MenuItem>
                                    <MenuItem value='NY'>NY</MenuItem>
                                    <MenuItem value='OH'>OH</MenuItem>
                                    <MenuItem value='OK'>OK</MenuItem>
                                    <MenuItem value='OR'>OR</MenuItem>
                                    <MenuItem value='PA'>PA</MenuItem>
                                    <MenuItem value='RI'>RI</MenuItem>
                                    <MenuItem value='SC'>SC</MenuItem>
                                    <MenuItem value='SD'>SD</MenuItem>
                                    <MenuItem value='TN'>TN</MenuItem>
                                    <MenuItem value='TX'>TX</MenuItem>
                                    <MenuItem value='UT'>UT</MenuItem>
                                    <MenuItem value='VA'>VA</MenuItem>
                                    <MenuItem value='VT'>VT</MenuItem>
                                    <MenuItem value='WA'>WA</MenuItem>
                                    <MenuItem value='WI'>WI</MenuItem>
                                    <MenuItem value='WV'>WV</MenuItem>
                                    <MenuItem value='WY'>WY</MenuItem>
                                </Select>
                            </div>
                            <div className={'form-group ' + (isMobile ? '' : 'marketplaceInline25')}>
                                <label className='marketplaceFormFieldLabel'>Zip Code</label>
                                <span className='marketplaceFormFieldLabelRequired'> *</span>
                                <TextField
                                    required
                                    fullWidth
                                    name='zip'
                                    type='zip'
                                    className='mt-1'
                                    value={data.zip}
                                    onChange={(e) => { handleInputChange(e) }}
                                    size='small'
                                />
                            </div>

                            <div className={'form-group ' + (isMobile ? '' : 'marketplaceInline50')}>
                                <label className='marketplaceFormFieldLabel'>Home Phone</label>
                                <span className='marketplaceFormFieldLabelRequired'> *</span>
                                <TextField
                                    required
                                    fullWidth
                                    name='homePhone'
                                    type='homePhone'
                                    className='mt-1'
                                    value={data.homePhone}
                                    onChange={(e) => { handleInputChange(e) }}
                                    size='small'
                                />
                            </div>
                            <div className={'form-group ' + (isMobile ? '' : 'marketplaceInline50')}>
                                <label className='marketplaceFormFieldLabel'>Cell Phone</label>
                                <span className='marketplaceFormFieldLabelRequired'> *</span>
                                <TextField
                                    required
                                    fullWidth
                                    name='cellPhone'
                                    type='cellPhone'
                                    className='mt-1'
                                    value={data.cellPhone}
                                    onChange={(e) => { handleInputChange(e) }}
                                    size='small'
                                />
                            </div>
                            <div className={'form-group ' + (isMobile ? '' : 'marketplaceInline50')}>
                                <label className='marketplaceFormFieldLabel'>Email</label>
                                <span className='marketplaceFormFieldLabelRequired'> *</span>
                                <TextField
                                    required
                                    fullWidth
                                    name='email'
                                    type='email'
                                    className='mt-1'
                                    value={data.email}
                                    onChange={(e) => { handleInputChange(e) }}
                                    size='small'
                                />
                            </div>
                            <div className={'form-group ' + (isMobile ? '' : 'marketplaceInline25')}>
                                <label className='marketplaceFormFieldLabel'>Sign up for SMS</label>
                                <span className='marketplaceFormFieldLabelRequired'> *</span>
                                <Select
                                    required
                                    fullWidth
                                    name='smsOptIn'
                                    className='mt-1'
                                    value={data.smsOptIn}
                                    defaultValue='No'
                                    onChange={(e) => { handleInputChange(e) }}
                                    MenuProps={{
                                        style: {
                                            maxHeight: 400,
                                        },
                                    }}
                                    size='small'>
                                    <MenuItem value='Yes'>Yes</MenuItem>
                                    <MenuItem value='No'>No</MenuItem>
                                </Select>
                            </div>
                            <div className={'form-group ' + (isMobile ? '' : 'marketplaceInline50')}>
                                <label className='marketplaceFormFieldLabel'>Select the languages you are fluent in?</label>
                                <div className='marketplaceFieldInputCheckLabel'><input name='french' className='marketplaceFieldInputCheck' checked={checkedFields.french} onChange={(e) => { handleCheckFields(e) }} type='checkbox' value='French' />  French</div>
                                <div className='marketplaceFieldInputCheckLabel'><input name='spanish' className='marketplaceFieldInputCheck' checked={checkedFields.spanish} onChange={(e) => { handleCheckFields(e) }} type='checkbox' value='Spanish' />  Spanish</div>
                            </div>
                            <div className={'form-group ' + (isMobile ? '' : 'marketplaceInline50')}>
                                <label className='marketplaceFormFieldLabel'>Contact Center Experience?</label>
                                <div className='marketplaceFieldInputCheckLabel'><input name='contactCenterPhoneSupport' className='marketplaceFieldInputCheck' checked={checkedFields.contactCenterPhoneSupport} onChange={(e) => { handleCheckFields(e) }} type='checkbox' value='Phone' />  Phone</div>
                                <div className='marketplaceFieldInputCheckLabel'><input name='contactCenterChatSupport' className='marketplaceFieldInputCheck' checked={checkedFields.contactCenterChatSupport} onChange={(e) => { handleCheckFields(e) }} type='checkbox' value='Chat' />  Chat</div>
                                <div className='marketplaceFieldInputCheckLabel'><input name='contactCenterEmailSupport' className='marketplaceFieldInputCheck' checked={checkedFields.contactCenterEmailSupport} onChange={(e) => { handleCheckFields(e) }} type='checkbox' value='Email' />  Email</div>
                            </div>
                            <div className={'form-group ' + (isMobile ? '' : 'marketplaceInline50')}>
                                <label className='marketplaceFormFieldLabel'>Additional Languages</label>
                                <TextField
                                    required
                                    fullWidth
                                    name='additonalLanguages'
                                    type='additonalLanguages'
                                    className='mt-1'
                                    value={data.additonalLanguages}
                                    onChange={(e) => { handleInputChange(e) }}
                                    size='small'
                                />
                            </div>
                            <div className={'form-group ' + (isMobile ? '' : 'marketplaceInline50')}>
                                <label className='marketplaceFormFieldLabel'>Highest level of education attained?</label>
                                <span className='marketplaceFormFieldLabelRequired'> *</span>
                                <Select
                                    required
                                    fullWidth
                                    name='education'
                                    className='mt-1'
                                    value={data.education}
                                    onChange={(e) => { handleInputChange(e) }}
                                    MenuProps={{
                                        style: {
                                            maxHeight: 400,
                                        },
                                    }}
                                    size='small'>
                                    <MenuItem value='Some High School'>Some High School</MenuItem>
                                    <MenuItem value='High School Diploma or GED'>High School Diploma or GED</MenuItem>
                                    <MenuItem value='Some College'>Some College</MenuItem>
                                    <MenuItem value={'Associate\\\'s Degree'}>Associate's Degree</MenuItem>
                                    <MenuItem value={'Bachelor\\\'s Degree'}>Bachelor's Degree</MenuItem>
                                    <MenuItem value='Some College'>Some College</MenuItem>

                                </Select>
                            </div>
                            <div className='gap-2 mt-3'>
                                <button disabled={lock} type='submit' className='marketplaceButton mt-2' onClick={(e) => { scheduleConfirmClick(e) }}>
                                    Confirm
                                </button>
                                <button disabled={lock} type='submit' className='marketplaceButton mt-2' onClick={(e) => { onCancelClick(e) }}>
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
                : <MarketplaceSpinner hidden={false} />
            }
        </>
    );
};

export default ScheduleConfirm;
