import { useState, useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import { sanitize } from 'dompurify';
import $ from 'jquery';

import { ShowMarketplaceError } from '../../utils/core/coreutil';
import GatewayAPI from '../../api/gateway/gatewayapi';
import MarketplaceSpinner from '../../components/shared/marketplacespinner/marketplacespinner';

const Gateway = () => {

    const marketplaceSessionGUID = useSelector((state) => state.core.marketplaceSessionGUID);
    const agentProfileGUID = useSelector((state) => state.core.agentProfileGUID);
    const agentProfileID = useSelector((state) => state.core.agentProfileID);
    const [data, setData] = useState({
        marketplaceSessionGUID: marketplaceSessionGUID,
        agentProfileGUID: agentProfileGUID,
        agentProfileID: agentProfileID,
        assessmentCompletedConversionJS: '',
        assessmentCompletedConversionURL: '',
        existingCallDateTime: '',
        existingSourcingScheduleID: 0
    });
    const [hideConversionURL, setHideConversionURL] = useState(true);
    const [formLabel, setFormLabel] = useState('');
    const [showButton, setShowButton] = useState({
        rescheduleCall: false,
        scheduleCall: false,
        cancelCall: false,
        editProfile: false,
        signUp: false,
    });
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        gatewayOnLoad();
    }, []);

    const gatewayOnLoad = () => {

        GatewayAPI.GatewayCheck(data).then((response) => {

            if (response.assessmentCompletedConversionURL !== '') {
                setData(values => {
                    return {
                        ...values,
                        assessmentCompletedConversionURL: response.assessmentCompletedConversionURL,
                        assessmentCompletedConversionJS: response.assessmentCompletedConversionJS,
                    }
                });

                setHideConversionURL(false);
            }

            if (response.assessmentCompletedConversionJS == 'recruitics') {

                $.getScript('https://jsv3.recruitics.com/3c7b1874-14f0-11ed-baf6-05069cd7a220.js', function() {
                  window.rx.trigger('apply');
                });
            }

            let gatewayStatus = response;

            switch (gatewayStatus.responseStatus.toLowerCase()) {
                case 'banned':
                    setFormLabel('There are currently no matching Marketplace opportunities.');
                    setShowButton(values => {
                        return {
                            ...values,
                            editProfile: true
                        }
                    });
                    break;
                case 'blocked':
                    setFormLabel('There are currently no matching Marketplace opportunities.');
                    setShowButton(values => {
                        return {
                            ...values,
                            editProfile: true
                        }
                    });
                    break;
                case 'existingcall':
                    setFormLabel('You currently have a call scheduled for <b>' + gatewayStatus.existingCallDateTime + '</b>.  Would you like to reschedule or cancel this call?');
                    setData(values => {
                        return {
                            ...values,
                            existingCallDateTime: gatewayStatus.existingCallDateTime,
                            existingSourcingScheduleID: gatewayStatus.sourcingScheduleID,
                        }
                    });
                    setShowButton(values => {
                        return {
                            ...values,
                            rescheduleCall: true,
                            cancelCall: true,
                            editProfile: true
                        }
                    });
                    break;
                case 'bypass-match':
                    setFormLabel('Congratulations you are a perfect match for this opportunity and can bypass the required Marketplace Coordinator call.  Click Sign Up to select a certification class which best fits your schedule.');
                    setShowButton(values => {
                        return {
                            ...values,
                            signUp: true,
                            editProfile: true
                        }
                    });
                    break;
                case 'programlanguagesource-match':
                    setFormLabel('Congratulations, you are a match for the opportunity that you are responding to.  To learn more about this opportunity click Schedule Call and speak with a Marketplace Coordinator.');
                    setShowButton(values => {
                        return {
                            ...values,
                            scheduleCall: true,
                            editProfile: true
                        }
                    });
                    break;
                case 'programonlysource-match':
                    setFormLabel('Congratulations, you are a match for the opportunity that you are responding to.  To learn more about this opportunity click Schedule Call and speak with a Marketplace Coordinator.');
                    setShowButton(values => {
                        return {
                            ...values,
                            scheduleCall: true,
                            editProfile: true
                        }
                    });
                    break;
                case 'nonsource-match':
                    setFormLabel('While you don\\\'t match the opportunity that you are responding to, there are other matching opportunities for you in the Marketplace.  To learn more about these opportunities click Schedule Call and speak with a Marketplace Coordinator.');
                    setShowButton(values => {
                        return {
                            ...values,
                            scheduleCall: true,
                            editProfile: true
                        }
                    });
                    break;
                case 'match':
                    setFormLabel('Congratulations, you match opportunities in the Marketplace.  To learn more about these opportunities click Schedule Call and speak with a Marketplace Coordinator.');
                    setShowButton(values => {
                        return {
                            ...values,
                            scheduleCall: true,
                            editProfile: true
                        }
                    });
                    break;
                case 'nomatch':
                    setFormLabel('While there is not a perfect match for you right now, please check back because new opportunities are added to the Marketplace all the time.');
                    setShowButton(values => {
                        return {
                            ...values,
                            editProfile: true
                        }
                    });
                    break;
                default:
                    setFormLabel(gatewayStatus);
            }
            setLoading(false);
        }).catch((e) => {
            if (e?.response?.data?.message.toLowerCase() === 'user session expired' || e?.response?.data?.message.toLowerCase() === 'failed to load usersession.') {
                navigate(0);
            }
            else
                ShowMarketplaceError(e.message, 'gateway.js', 'gatewayOnLoad()');
        });
    };

    const editProfileClick = () => {
        navigate('/profileedit');
    };

    const scheduleCallClick = () => {
        navigate('/scheduleavailability');
    };

    const rescheduleCallClick = () => {
        navigate('/scheduleavailability', { state: { existingCallDateTime: data.existingCallDateTime, existingSourcingScheduleID: data.existingSourcingScheduleID } });
    };

    const cancelCallClick = () => {
        navigate('/schedulecancel', { state: { existingCallDateTime: data.existingCallDateTime, existingSourcingScheduleID: data.existingSourcingScheduleID } });
    };

    return (
        <>{!loading ?
            <div className='marketplaceFormContainer'>
                <form id='marketplaceForm' className='marketplaceForm' style={{ height: '240px' }}>
                    <div className='marketplaceFormContent'>
                        <h3 className='marketplaceFormTitle'>NexRep Contractor Marketplace</h3>
                        <p dangerouslySetInnerHTML={{ __html: sanitize(formLabel) }}></p>
                        <img hidden={hideConversionURL} src={data.assessmentCompletedConversionURL} />
                        <div className='gap-2 mt-3'>
                            <button hidden={!showButton.rescheduleCall} onClick={rescheduleCallClick} type='submit' className='marketplaceButton mt-2' >
                                Rechedule Call
                            </button>
                            <button hidden={!showButton.cancelCall} onClick={cancelCallClick} type='submit' className='marketplaceButton mt-2' >
                                Cancel Call
                            </button>
                            <button hidden={!showButton.scheduleCall} onClick={scheduleCallClick} type='submit' className='marketplaceButton mt-2' >
                                Schedule Call
                            </button>
                            <button hidden={!showButton.signUp} type='submit' className='marketplaceButton mt-2' >
                                Sign Up
                            </button>
                            <button hidden={!showButton.editProfile} onClick={editProfileClick} type='submit' className='marketplaceButton mt-2' >
                                Edit Profile
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            : <MarketplaceSpinner hidden={false} />
        }
        </>
    );

};

export default Gateway;