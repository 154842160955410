import PropTypes from 'prop-types';
import { FormControl, MenuItem, Select } from "@mui/material";
import { Controller } from "react-hook-form";

const FormSelect = (props) => {

    const {stepDetails, errors, control} = props;

    return (
        <FormControl fullWidth>
            <label className={[stepDetails.promptLabelClass, (stepDetails.isFlex && stepDetails.promptLabelColumns && stepDetails.promptLabelColumns !== '' ? ' col-' + stepDetails.promptLabelColumns : '')]} >{stepDetails.promptLabel}<span className='marketplaceProcessRequired'>{stepDetails.validateRequired && ' *'}</span></label>
            <Controller
                id={stepDetails.reference}
                control={control}
                name={stepDetails.referenceValidate}
                defaultValue={stepDetails.fieldValue}
                render={({ field }) => (
                    <Select
                        {...field}
                        fullWidth
                        name={stepDetails.reference}
                        className={'mt-1 ' + stepDetails.promptValueClass}
                        options={stepDetails.selectValues}
                        menuprops={{
                            style: {
                                maxHeight: 400,
                            },
                        }}
                        size='small'>
                        {stepDetails.selectValues.map((source, index) => (
                            <MenuItem key={index} value={source.Value}>{source.Label}</MenuItem>
                        ))}
                    </Select>
                )}
                rules={{ required: stepDetails.validateRequired }}
            />
            {errors && errors[stepDetails.referenceValidate]?.type === 'required' && (
                <label className='marketplaceProcessRequired'>This field is required.</label>
            )}
        </FormControl>
    );
};

export default FormSelect;

FormSelect.propTypes = {
    stepDetails: PropTypes.any.isRequired,
    errors: PropTypes.any,
    control: PropTypes.any.isRequired
};
