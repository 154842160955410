

import { Checkbox, FormControlLabel } from '@mui/material';
import PropTypes from 'prop-types';

const FormCheckbox = (props) => {

    const { stepDetails, errors, register } = props;

    const isChecked = (source) => {
        if (source?.Value?.toLowerCase() === stepDetails?.fieldValue?.toLowerCase() || source?.Selected?.toLowerCase() === 'yes')
            return true;
    };

    return (
        <>
            <label className={stepDetails.promptLabelClass + ' ' + (stepDetails.isFlex && stepDetails.promptLabelColumns && stepDetails.promptLabelColumns !== '' ? ' col-' + stepDetails.promptLabelColumns : '')}>{stepDetails.promptLabel}<span className='marketplaceProcessRequired'>{stepDetails.validateRequired && ' *'}</span></label>
            <div className={stepDetails.promptValueClass + ' ' + (stepDetails.isFlex && stepDetails.promptValueColumns && stepDetails.promptValueColumns !== '' ? ' flex-radio' : '')}>
                {stepDetails.selectValues.map((source, index) => (
                    <div key={index} className={'form-check marketplaceProcessRadioButtonFieldSet ' + (stepDetails.isFlex && stepDetails.promptValueColumns ? ' col-' + stepDetails.promptValueColumns : '')}>
                        <FormControlLabel
                            label={source.Label ? source.Label : source.Value}
                            control={
                                <Checkbox {...register(stepDetails.reference + index)} key={index} checked={isChecked(source)} value={source.Value} name={stepDetails.reference}
                                    sx={{
                                        color: '#393939',
                                        '&.Mui-checked': {
                                            color: '#393939',
                                        },
                                    }}
                                />
                            }
                        />
                    </div>
                ))}
            </div>
            {errors && errors[stepDetails.referenceValidate]?.type === 'required' && (
                <label className='marketplaceProcessRequired'>This field is required.</label>
            )}
        </>
    );
};

export default FormCheckbox;

FormCheckbox.propTypes = {
    stepDetails: PropTypes.any.isRequired,
    errors: PropTypes.any,
    register: PropTypes.any,
};
