import PropTypes from 'prop-types';
import { FormControl } from "@mui/material";
import { Controller } from "react-hook-form";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const FormHTMLEditor = (props) => {

    const { stepDetails, errors, control, setValue } = props;

    return (
        <FormControl fullWidth>
            <label className={[stepDetails.promptLabelClass, (stepDetails.isFlex && stepDetails.promptLabelColumns && stepDetails.promptLabelColumns !== '' ? ' col-' + stepDetails.promptLabelColumns : '')]} >{stepDetails.promptLabel}<span className='marketplaceProcessRequired'><span className='marketplaceProcessRequired'>{stepDetails.validateRequired && ' *'}</span></span></label>
            <Controller
                control={control}
                name={stepDetails.referenceValidate}
                defaultValue={stepDetails.fieldValue}
                render={({ field: { value, onChange } }) => (
                    <>
                        <CKEditor
                            editor={ClassicEditor}
                            className={stepDetails.promptValueClass}
                            data={stepDetails.fieldValue}
                            onChange={(e, editor) => {
                                setValue(stepDetails.referenceValidate, editor.getData())
                            }}
                        />
                        <input type='hidden' name={stepDetails.reference} onChange={onChange} id={stepDetails.reference} value={value} />
                    </>
                )}
                rules={{ required: stepDetails.validateRequired }}
            />
            {errors && errors[stepDetails.referenceValidate]?.type === 'required' && (
                <label className='marketplaceProcessRequired'>This field is required.</label>
            )}
        </FormControl>
    );

};

export default FormHTMLEditor;

FormHTMLEditor.propTypes = {
    stepDetails: PropTypes.any.isRequired,
    errors: PropTypes.any,
    control: PropTypes.any.isRequired,
    setValue: PropTypes.any,
};
