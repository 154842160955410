import axios from "axios";
import { socratesAPIEndpoint } from '../../components/shared/environment';


const GatewayAPI = ({

    GatewayCheck: function (data) {

        try {

            return axios({
                url: `${socratesAPIEndpoint}/SocratesMarketplace/GatewayCheck`,
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify({
                    'MarketplaceSessionGUID': data.marketplaceSessionGUID,
                    'agentProfileID': data.agentProfileID,
                    'agentProfileGUID': data.agentProfileGUID
                })
            }).then(response => response.data);
        }
        catch (error) {
            return error;
        }
    }
});

export default GatewayAPI;